import {useStore} from '@/store'

import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import { Card,InfiniteScroll } from 'antd-mobile'
import { FileOutline } from 'antd-mobile-icons'
import { Input,Affix } from 'antd';


const { Search } = Input;

function PaymentCollection(){
  
  const [data, setData] = useState([])
  const {PaymentCollectionStore} = useStore()
  

  const [hasMore, setHasMore] = useState(true)
  async function loadMore() {
    PaymentCollectionStore.setpage(PaymentCollectionStore.page + 1)
    const res = await PaymentCollectionStore.GetPaymentCollection('')
    setData(val => [...val, ...res])
    setHasMore(res.length > 0)
  }

  const onSearch = async (value)=>{
    if(value === ""){
      setHasMore(true)
      PaymentCollectionStore.setpage(0)
      setData([])
    }
    else{
      setHasMore(false)
      PaymentCollectionStore.setpage(1)
      const res = await PaymentCollectionStore.GetPaymentCollection(value)
      setData([...res])
    }
    
    
  }

  return(
    <div>
    <div className='search-top'>
      <Affix offsetTop={70}>
        <Search size="large" placeholder="请输入搜索内容" allowClear enterButton onSearch={onSearch} />
      </Affix>
    </div>
    <div>
    {
    data.map((item,index)=>{
      return (
          
        <Card title={
          (<>
            <div className='title-main'>
              <div className='title-1'>
                <div><FileOutline fontSize={24} color='var(--adm-color-primary)'/>
                <span>基本信息</span></div>
              </div>
            </div>
          </>)
        } background='gray' key={index} >
          <div className="lefttitle">回款金额:<span className="content">{item.amount}</span></div>
          <div className="lefttitle">回款方式:<span className="content">{item.payment_type_info}</span></div>
          <div className="lefttitle">回款时间:<span className="content">{item.pay_time}</span></div>
          <div className="lefttitle">提交人:<span className="content">{item.submit_info}</span></div>
          <div className="lefttitle">合同编号:<span className="content">{item.contract_no}</span></div>
          <div className="lefttitle">合同名称:<span className="content">{item.contract_name}</span></div>
          <div className="lefttitle">销售:<span className="content">{item.contract_sale}</span></div>
          <div className="lefttitle">审批人:<span className="content">{item.approval_user}</span> </div>
          <div className="lefttitle">审批状态:<span className="content">{item.approval_status_info}</span> </div>
          <div className="lefttitle">审核时间:<span className="content">{item.approval_at}</span> </div>
          <div className="lefttitle">创建时间:<span className="content">{item.created_at}</span> </div>
        </Card>
      )
    })
    }
    </div>
    <div><InfiniteScroll loadMore={loadMore} hasMore={hasMore} /></div>
  </div>
  )
}

export default observer(PaymentCollection); 