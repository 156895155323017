import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import { Card,InfiniteScroll } from 'antd-mobile'
import { FileOutline } from 'antd-mobile-icons'
import { Input,Affix } from 'antd';

import {useStore} from '@/store'


const { Search } = Input;

function InvoiceManagement(){

  const [data, setData] = useState([])
  const {InvoiceManagementStore} = useStore()


  const [hasMore, setHasMore] = useState(true)
  async function loadMore() {
    InvoiceManagementStore.setpage(InvoiceManagementStore.page + 1)
    const res = await InvoiceManagementStore.getAllInvoice('')
    setData(val => [...val, ...res])
    setHasMore(res.length > 0)
  }

  const onSearch = async (value)=>{
    if(value === ""){
      setHasMore(true)
      InvoiceManagementStore.setpage(0)
      setData([])
    }
    else{
      setHasMore(false)
      InvoiceManagementStore.setpage(1)
      const res = await InvoiceManagementStore.getAllInvoice(value)
      setData([...res])
    }
    
    
  }

  return (
    <div>
      <div className='search-top'>
        <Affix offsetTop={70}>
          <Search size="large" placeholder="请输入搜索内容" allowClear enterButton onSearch={onSearch} />
        </Affix>
      </div>
      <div>
      {
      data.map((item,index)=>{
        return (
            
          <Card title={
            (<>
              <div className='title-main'>
                <div className='title-1'>
                  <div><FileOutline fontSize={24} color='var(--adm-color-primary)'/>
                  <span>基本信息</span></div>
                </div>
              </div>
            </>)
          } background='gray' key={index} >
            <div className="lefttitle">开票金额:<span className="content">{item.amount}</span></div>
            <div className="lefttitle">合同编号:<span className="content">{item.contract_no}</span></div>
            <div className="lefttitle">合同名称:<span className="content">{item.contract_name}</span></div>
            <div className="lefttitle">合同金额:<span className="content">{item.contract_amount}</span></div>
            <div className="lefttitle">客户名称:<span className="content">{item.customer_name}</span></div>
            <div className="lefttitle">客户单位:<span className="content">{item.work_unit}</span></div>
            <div className="lefttitle">开票类型:<span className="content">{item.customer_type_info}</span></div>
            <div className="lefttitle">发票类型:<span className="content">{item.invoice_type_info}</span> </div>
            <div className="lefttitle">发票所属:<span className="content">{item.belong}</span></div>
            <div className="lefttitle">开票银行:<span className="content">{item.bank_type_info}</span> </div>
            <div className="lefttitle">开票内容:<span className="content">{item.content_desc}</span> </div>
            <div className="lefttitle">创建时间:<span className="content">{item.created_at}</span> </div>


            <div className="lefttitle">审批人:<span className="content">{item.user}</span> </div>
            <div className="lefttitle">审批状态:<span className="content">{item.status_name}</span> </div>
          </Card>
        )
      })
      }
      </div>
      <div><InfiniteScroll loadMore={loadMore} hasMore={hasMore} /></div>
    </div>
  )
}

export default observer(InvoiceManagement);