import { makeAutoObservable } from "mobx"
import { http } from '@/utils'


const BASE_API = 'api/v1/wechat/'


class contractManagementStore {
  page = 0
  constructor() {
    makeAutoObservable(this)
  }

  // get_contract_list_data
  getLontractListData =  async (search)=>{
    const res = await http.post(BASE_API+'get_contract_list_data',{page:this.page,limit:10,data_type:1,search:search})
    return res.data.data
  }

  getContractProgressByContractId = async (contract_id)=>{
    const res = await http.get(BASE_API+'get_contract_progress_by_contract_id',{params:{contract_id}})
    return res.data
  }

  setpage = (page)=> {
    this.page = page
  }

}

export default contractManagementStore