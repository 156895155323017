// 登录模块
import { makeAutoObservable } from "mobx"
import md5 from "js-md5"

import { http, getToken, setToken,clearToken } from '@/utils'

const BASE_API = 'api/v1/wechat/'

class LoginStore {
  token = getToken() || ''
  constructor() {
    makeAutoObservable(this)
  }
  // 登录
  login = async ({ username, password }) => {
    password = md5(password)
    const res = await http.post(BASE_API+'login', {username, password})
    if(res.code === 1){
      this.token = res.data.access_token
      setToken(res.data.access_token)
      
    }
    else{
      throw new Error(res.msg)
    }
    
  }

  // 获取验证码
  getCode = async({phone,type}) => {
  
    const res = await http.post(BASE_API+'send_code',{phone,type})
    if(res.code === 1){
      
    }
    else{
      throw new Error("发送验证码失败")
    }

  }

  // 手机登录
  phoneLogin = async({phone,code}) => {
    const res = await http.post(BASE_API+'phone_login',{phone,code})
    if(res.code === 1){
      this.token = res.data.access_token
      setToken(res.data.access_token)
      
    }
    else{
      throw new Error(res.msg)
    }
  }
  outLogin = ()=>{
    this.token = ''
    clearToken()
  }
}
export default LoginStore