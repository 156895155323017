import React from "react"
import LoginStore from './login.Store'
import ContractProgressStore from './contract_progress.Store'
import ContractManagementStore from './contract_management.Store'
import InvoiceManagementStore from './invoice_management.Store'
import PaymentCollectionStore from './payment_collection.Store'


class RootStore {
  // 组合模块
  constructor() {
    this.loginStore = new LoginStore()
    this.lontractProgressStore = new ContractProgressStore()
    this.lontractManagementStore = new ContractManagementStore()
    this.InvoiceManagementStore = new InvoiceManagementStore()
    this.PaymentCollectionStore = new PaymentCollectionStore()
   
  }
}
// 导入useStore方法供组件使用数据
const StoresContext = React.createContext(new RootStore())
export const useStore = () => React.useContext(StoresContext)