import {Route, Routes } from 'react-router-dom'

import { HashRouter, history } from './utils/history'

import './App.css';
import Login from '@/pages/Login/login'
import Layout from '@/pages/Layout/layout'
import ContractProgress from '@/pages/ContractProgress/contract_progress'
import ContractManagement from '@/pages/ContractManagement/contract_management'
import InvoiceManagement from '@/pages/InvoiceManagement/invoice_management'
import PaymentCollection from '@/pages/PaymentCollection/payment_collection'
import { AuthRoute } from '@/components/AuthRoute'


function App() {
  return (
    <HashRouter history={history}>
      {/* <HistoryRouter history={history}> */}
        <div className="App">
          <Routes>
            <Route path='/' element={
              <AuthRoute>
                <Layout />
              </AuthRoute>
            } >
              <Route path='/contract_progress' element={<ContractProgress />} />
              <Route path='/contract_management' element={<ContractManagement />} />
              <Route path='/invoice_management' element={<InvoiceManagement />} />
              <Route path='/payment_collection' element={<PaymentCollection />} />
              
            </Route>
            <Route path='/login' element={<Login /> }/>
             
          </Routes>
        </div>
      {/* </HistoryRouter> */}
    </HashRouter>
    
    
  );
}

export default App;
