import './index.scss'
import logo from '@/assets/imgs/loginLogo.png'

import { LockOutlined, UserOutlined,PhoneOutlined } from '@ant-design/icons';
import { Card,Input,Form,Button,Col,Row,message} from 'antd'
import { useState,useRef } from 'react';
import {useNavigate} from 'react-router-dom'
import {observer} from 'mobx-react-lite'

import {useStore} from '@/store'

const Login = ()=>{

	const [login_type, setlogin_type] = useState(1)

	const tabsOnClick = ()=>{
		if(login_type === 1){
      setlogin_type(2)
		}else{
      setlogin_type(1)}
	}

	// 账户密码登录
	const {loginStore} = useStore()
	const navigate = useNavigate()
	const onFinishUser = async (values) => {
		
		const { username, password } = values
    try {
      await loginStore.login({ username, password })
      navigate('/')
      message.success('登录成功')
    } catch (e) {
			// console.log(e)
      message.error(e.message)
    }
  }


	// 手机验证码登录
	const telref = useRef(null)
	const onFinishPhone = async (values) => {
		const { phone, code } = values
		try{
			await loginStore.phoneLogin({phone, code})
			navigate('/')
			message.success('登录成功')
		}catch(e) {
			message.error(e.message)
		}

	}

	// 获取手机验证码
	var [code_time,setcode_time] = useState(15)
	const [code_type,setcode_type] = useState(false)
	const getCode = ()=>{
		 telref.current.validateFields(['phone']).then(async (values)  =>  {
			const { phone } = values
			try {
				await loginStore.getCode({ phone ,type:1})
				message.success('发送成功')
				setcode_type(true)
				let time = setInterval(()=>{
					if(code_time <= 0){
						setcode_type(false)
						clearInterval(time)
					}else{
						setcode_time(code_time = code_time-1)
					}
	
				},1000)
			}catch(e) {
				message.error(e.message)
			}
			
		})
	}
  return (
		
		<div className="login">
			<Card className="login-container">
				<img className="login-logo" src={logo} alt="" />
				{
					login_type === 1?(
						<Form name="basic" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} style={{ maxWidth: 600, }}
							autoComplete="off"
							onFinish={ onFinishUser }
						>
							<Form.Item name="username" rules={[{ required: true, message: '请输入用户名', },]}>
								<Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder='请输入用户名' />
							</Form.Item>

							<Form.Item name="password" rules={[{ required: true, message: '请输入密码', },]}>
								<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} size="large" placeholder='请输入密码' />
							</Form.Item>

							<Form.Item>
								<Button type="primary" size="large" htmlType="submit" className="login-form-button-1">Log in</Button>
								<Button type="link" htmlType="button" onClick={tabsOnClick} className="login-form-button-2">手机登录</Button>
							</Form.Item>
						</Form>
					):(
							<Form ref={telref} name="basic" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} style={{ maxWidth: 600, }}
								autoComplete="off"
								onFinish={ onFinishPhone }
							>
								<Form.Item name="phone" rules={[{ required: true, message: '请输入手机号', },]}>
									<Input prefix={<PhoneOutlined className="site-form-item-icon" />} size="large" placeholder='请输入手机号' />
								</Form.Item>

								<Form.Item>
									<Row gutter={8}>
										<Col span={15}>
											<Form.Item name="code" noStyle 
											rules={[{required: true,message: '请输入您的验证码!',}]}>
												<Input size="large" placeholder='请输入验证码'/>
											</Form.Item>
										</Col>
										<Col span={9}>
											{
												code_type !== true?(
													<Button size="large" onClick={getCode}>获取验证码</Button>
												):(
													<Button size="large" disabled>{code_time}秒后操作</Button>
												)
											}
											
											
										</Col>
									</Row>
								</Form.Item>

								<Form.Item>
									<Button type="primary" size="large" htmlType="submit" className="login-form-button-1">Log in</Button>
									<Button type="link" htmlType="button" onClick={tabsOnClick} className="login-form-button-2">账户登录</Button>
								</Form.Item>
							</Form>
					)
				}
				
				
			</Card>
		</div>
			
  )
}

export default observer(Login)