import { makeAutoObservable } from "mobx"
import { http } from '@/utils'


const BASE_API = 'api/v1/wechat/'


class paymentCollectionStore {
  page = 0
  constructor() {
    makeAutoObservable(this)
  }

  GetPaymentCollection  = async (search)=>{
    const res = await http.post(BASE_API+'get_payment_collection',{page:this.page,limit:10,data_type:1,search:search})

    return res.data.data
  }
  setpage = (page)=> {
    this.page = page
  }
}

export default paymentCollectionStore