import {useState,useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import { FileOutline,ClockCircleOutline } from 'antd-mobile-icons'
import { Card,InfiniteScroll,Popup,Steps } from 'antd-mobile'
import { Input,Affix } from 'antd';

import {useStore} from '@/store'

import './index.scss'


const { Search } = Input;
const { Step } = Steps;

const ContractProgress = ()=>{
  
  const {lontractProgressStore} = useStore()
  const [data, setData] = useState([])
  useEffect(() =>{
    lontractProgressStore.setpage(0)
  
    
  },[lontractProgressStore])


  

  const onSearch = async (value)=>{
    if(value === ""){
      setHasMore(true)
      lontractProgressStore.setpage(0)
      setData([])
    }
    else{
      setHasMore(false)
      lontractProgressStore.setpage(1)
      const res = await lontractProgressStore.getContractProgress(value)
      setData([...res])
    }
    
    
  }

  
  const [hasMore, setHasMore] = useState(true)
  async function loadMore() {
    lontractProgressStore.setpage(lontractProgressStore.page + 1)
    const res = await lontractProgressStore.getContractProgress('')
    setData(val => [...val, ...res])
    setHasMore(res.length > 0)
    
  }
  const [visible1, setVisible1] = useState(false)
  const [list1,setlist1] = useState({
    row:{},
    list:[]
  })
  const deleteClick = async (row)=>{
    setVisible1(true)
    const res = await lontractProgressStore.getContractProgressInfo(row.id)
    setlist1({row:row,list:[...res]})
    console.log(list1)
  }

  

  return (
    <div className='contract-main'>
      <div className='search-top'>
      <Affix offsetTop={70}>
      <Search size="large" placeholder="请输入搜索内容" allowClear enterButton onSearch={onSearch} />
      </Affix>
      
      </div>
      <div>
      {
      data.map((item,index)=>{
        return (
            
          <Card title={
            (<>
              <div className='title-main'>
                <div className='title-1'>
                  <div><FileOutline fontSize={24} color='var(--adm-color-primary)'/>
                  <span>基本信息</span></div>
                </div>
                {/* <div className='title-2' onClick={()=> deleteClick(item)}>详情</div> */}
              </div>
            </>)
          } background='gray' key={index} onClick={()=> deleteClick(item)}>
            <div className="lefttitle">项目编号: <span className="content">{item.contract_no}</span> </div>
            <div className="lefttitle">项目名称: <span className="content">{item.contract_name}</span> </div>
            <div className="lefttitle">客户名称: <span className="content">{item.customer_name}</span> </div>
            <div className="lefttitle">销售经理: <span className="content">{item.sale}</span> </div>
            <div className="lefttitle">所属大区: <span className="content">{item.region}</span></div>
            <div className="lefttitle">项目状态: <span className="content">{item.project_status_info}</span></div>
          </Card>
        )
      })
      }
      </div>
      

      <div><InfiniteScroll loadMore={loadMore} hasMore={hasMore} /></div>

      <Popup
        visible={visible1}
        onClose={() => {setVisible1(false)}}
        showCloseButton
      >
        <div style={{ height: '60vh', overflowY: 'scroll', padding: '20px' }}>
          <Card background='gray'>
            <div className="lefttitle">项目编号: <span className="content">{list1.row.contract_no}</span> </div>
            <div className="lefttitle">项目名称: <span className="content">{list1.row.contract_name}</span> </div>
            <div className="lefttitle">客户名称: <span className="content">{list1.row.customer_name}</span> </div>
            <div className="lefttitle">销售经理: <span className="content">{list1.row.sale}</span> </div>
            <div className="lefttitle">所属大区: <span className="content">{list1.row.region}</span></div>
            <div className="lefttitle">项目状态: <span className="content">{list1.row.project_status_info}</span></div>
          </Card>

          <div className='title-3'><ClockCircleOutline color='var(--adm-color-primary)'/>进度信息</div>
          <Steps direction='vertical'>
            {
              list1.list.map((item,index)=>{
                return(
                  <Step title={item.project_desc} description={'创建时间：'+ item.created_at} status='process' key={index}/>
                )
              })
            }
            
          </Steps>
          
        </div>
      </Popup>
    </div>
  )
}

export default observer(ContractProgress)