
import './index.scss'
import {useState,useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import { Card,InfiniteScroll,Popup,Steps } from 'antd-mobile'
import { FileOutline,ClockCircleOutline } from 'antd-mobile-icons'
import { Input,Affix } from 'antd';

import {useStore} from '@/store'

const { Search } = Input;
const { Step } = Steps;

function ContractManagement(){


  const {lontractManagementStore} = useStore()
  
  const [data, setData] = useState([])

  useEffect(()=>{
    lontractManagementStore.setpage(0)
  },[lontractManagementStore])


  const [hasMore, setHasMore] = useState(true)
  async function loadMore() {
    lontractManagementStore.setpage(lontractManagementStore.page + 1)
    const res = await lontractManagementStore.getLontractListData('')
    setData(val => [...val, ...res])
    setHasMore(res.length > 0)
  }

  const onSearch = async (value)=>{
    if(value === ""){
      setHasMore(true)
      lontractManagementStore.setpage(0)
      setData([])
    }
    else{
      setHasMore(false)
      lontractManagementStore.setpage(1)
      const res = await await lontractManagementStore.getLontractListData(value)
      setData([...res])
    }
    
    
  }

  const [visible1, setVisible1] = useState(false)
  const [list1,setlist1] = useState({
    data:{
      progress_info:[]
    }
  })
  const deleteClick = async (row)=>{
    const res = await lontractManagementStore.getContractProgressByContractId(row.id)
    if(res.length === 0){
      setlist1({data:{
        progress_info:[]
      }})
    }else{
      setlist1({data:res[0]})
    }
    setVisible1(true)
  }

  return (
    <div>
      <div className='search-top'>
      <Affix offsetTop={70}>
      <Search size="large" placeholder="请输入搜索内容" allowClear enterButton onSearch={onSearch} />
      </Affix>
      </div>
      <div>
      {
      data.map((item,index)=>{
        return (
            
          <Card title={
            (<>
              <div className='title-main'>
                <div className='title-1'>
                  <div><FileOutline fontSize={24} color='var(--adm-color-primary)'/>
                  <span>基本信息</span></div>
                </div>
              </div>
            </>)
          } background='gray' key={index} >
            <div className="lefttitle">合同编号:<span className="content">{item.no}</span></div>
            <div className="lefttitle">合同名称:<span className="content">{item.name}</span></div>
            <div className="lefttitle">合同金额:<span className="content">{item.amount}</span></div>
            <div className="lefttitle">产品线: <span className="content">{item.product_line_name}</span></div>
            <div className="lefttitle">客户名称:<span className="content">{item.customer_name}</span></div>
            <div className="lefttitle">客户单位:<span className="content">{item.customer_unit}</span></div>
            <div className="lefttitle">大区经理:<span className="content">{item.region_leader_name}</span> </div>
            <div className="lefttitle">所属大区:<span className="content">{item.region_info}</span></div>
            <div className="lefttitle">创建人: <span className="content">{item.user}</span> </div>
            <div className="lefttitle">创建时间:<span className="content">{item.created_at}</span> </div>
            <div className="lefttitle">审批人:<span className="content">{item.approval}</span> </div>
            <div className="lefttitle">审批状态:<span className="content">{item.status}</span> </div>
            {/* <div className='jindu' onClick={()=> deleteClick(item)}>进度查看</div> */}
          </Card>
        )
      })
      }
      </div>
      <div><InfiniteScroll loadMore={loadMore} hasMore={hasMore} /></div>

      <Popup
        visible={visible1}
        onClose={() => {setVisible1(false)}}
        showCloseButton
      >
        <div style={{ height: '60vh', overflowY: 'scroll', padding: '20px' }}>
          <Card background='gray'>
            <div className="lefttitle">项目编号: <span className="content">{list1.data.contract_no}</span> </div>
            <div className="lefttitle">项目名称: <span className="content">{list1.data.contract_name}</span> </div>
            <div className="lefttitle">客户名称: <span className="content">{list1.data.customer_name}</span> </div>
            <div className="lefttitle">销售经理: <span className="content">{list1.data.sale}</span> </div>
            <div className="lefttitle">所属大区: <span className="content">{list1.data.region}</span></div>
            <div className="lefttitle">项目状态: <span className="content">{list1.data.project_status_info}</span></div>
          </Card>

          <div className='title-3'><ClockCircleOutline color='var(--adm-color-primary)'/>进度信息</div>
          <Steps direction='vertical'>
            {
              list1.data.progress_info.map((item,index)=>{
                return(
                  <Step title={item.project_desc} status='process' key={index}/>
                )
              })
            }
            
          </Steps>
          
        </div>
      </Popup>
    </div>
  )
}

export default observer(ContractManagement)