import { makeAutoObservable,runInAction } from "mobx"
import { http } from '@/utils'


const BASE_API = 'api/v1/wechat/'
class ContractProgressStore{

  tabledata = []
  page = 0

  constructor() {
    makeAutoObservable(this)
  }

  getContractProgress = async (search)=> {

    const res = await http.post(BASE_API+'get_contract_progress',{page:this.page,limit:10,data_type:1,search:search})
    
    runInAction(()=>{
      this.tabledata = res.data.data
    })
    return res.data.data
  }

  // get_contract_progress_info
  getContractProgressInfo = async (id)=> {
    const res = await http.get(BASE_API+'get_contract_progress_info',{params: {progress_id:id}})
    return res.data
  }

  setpage = (page)=> {
    this.page = page
  }
}

export default ContractProgressStore