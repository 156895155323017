import { CapsuleTabs } from 'antd-mobile'
import {Outlet,useNavigate} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import './index.scss'

import { clearToken } from '@/utils'

const Layout = ()=>{


  const navigate = useNavigate()
  const onChangeTabs = (tab)=>{
    if(tab === 'out_login'){
      clearToken()
      navigate('/login')
      return
    }
    navigate(tab)
  }
  return (
    <div>
     
      <CapsuleTabs defaultActiveKey='' onChange={onChangeTabs}>
        <CapsuleTabs.Tab title='合同查询' key='contract_management'>
          <div className='tab-main'>
            <Outlet />
          </div>
          
        </CapsuleTabs.Tab>
        <CapsuleTabs.Tab title='发票查询' key='invoice_management'>
          <div className='tab-main'>
            <Outlet />
          </div>
          
        </CapsuleTabs.Tab>
        <CapsuleTabs.Tab title='回款查询' key='payment_collection'>
          <div className='tab-main'>
            <Outlet />
          </div>
          
        </CapsuleTabs.Tab>
        <CapsuleTabs.Tab title='项目进度' key='contract_progress'>
          <div className='tab-main'>
            <Outlet />
          </div>
        </CapsuleTabs.Tab>
        <CapsuleTabs.Tab title='退出登录' key='out_login'>
          <div className='tab-main'>
            <Outlet />
          </div>
        </CapsuleTabs.Tab>
      </CapsuleTabs>
        
      
    </div>
  )
}

export default observer(Layout)